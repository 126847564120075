import { PlayerState } from "../types";

const STORAGE_KEY = "player-state-data";

export default class StateManager {
  public static load(): PlayerState {
    const data = localStorage.getItem(STORAGE_KEY);

    if (!data) {
      return StateManager.default();
    }

    return JSON.parse(data) as PlayerState;
  }

  public static default(): PlayerState {
    return {
      bitrate: -1,
      playing: false,
      volume: 0,
      muted: false,
    };
  }

  public static save(state: PlayerState): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  }
}
