import React from "react";
import { DriverInterface } from "../../driver/DriverInterface";
import { VideoProgress } from "../../types";
import styles from "./Progress.module.scss";

const formatDuration = (value: number) => {
  const minutes = Math.floor(value / 60);
  const seconds = Math.round(value - minutes * 60);

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

type Props = {
  driver: DriverInterface;
};

function Progress({ driver }: Props) {
  const [progress, setProgress] = React.useState<VideoProgress>(
    driver.progress()
  );

  const mutate = React.useCallback(() => {
    if (!driver.ready()) return;

    setProgress(driver.progress());
  }, [driver]);

  React.useEffect(() => {
    const interval = setInterval(mutate, 1_000);
    return () => clearInterval(interval);
  }, [mutate]);

  return (
    <div className={styles.container}>
      <div className={styles.times}>
        <span>{formatDuration(progress.elapsed)}</span>
        <span>{formatDuration(progress.duration)}</span>
      </div>
      <input
        type="range"
        min={0}
        step={0.1}
        className={styles.bar}
        value={progress.elapsed}
        max={progress.duration}
        onChange={(e) => driver.seek(Number(e.target.value))}
      />
    </div>
  );
}

export default Progress;
