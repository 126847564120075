import styles from "./Volume.module.scss";
import React from "react";

type Props = {
  value: number;
  onChange: (value: number) => void;
};

function Volume({ value, onChange }: Props) {
  return (
    <input
      className={styles.slider}
      type="range"
      min={0}
      max={1}
      step={0.01}
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
    />
  );
}

export default Volume;
