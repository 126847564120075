import { DriverInterface } from "./DriverInterface";
import Hls from "hls.js";
import dashjs from "dashjs";
import DashDriver from "./DashDriver";
import HLSDriver from "./HLSDriver";
import NativeDriver from "./NativeDriver";

export default class DriverFactory {
  public static make(): DriverInterface {
    const element = document.createElement("video");
    const hasNativeSupport = element.canPlayType(
      "application/vnd.apple.mpegurl"
    );

    if (hasNativeSupport) {
      return new NativeDriver();
    }

    if (Hls.isSupported()) {
      return new HLSDriver();
    }

    if (dashjs.supportsMediaSource()) {
      return new DashDriver();
    }

    throw new Error("Device not supported.");
  }
}
