import React from "react";
import StateManager from "../../manager/StateManager";
import { DriverInterface } from "../../driver/DriverInterface";
import { PlayerParams, StreamStatus } from "../../types";
import useApi from "../../hooks/useApi";
import Controls from "../Controls";
import Progress from "../Progress";
import Header from "../Header";
import styles from "./Player.module.scss";

type Props = {
  driver: DriverInterface;
  params: PlayerParams;
};

function Player({ driver, params }: Props) {
  const api = useApi();

  const [live, setLive] = React.useState<boolean>();
  const [status, setStatus] = React.useState<StreamStatus>();

  React.useEffect(() => {
    if (!params.videoId) return;

    api
      .get<{ isInput: boolean; status: StreamStatus }>(
        `/${params.videoId}/lifecycle`
      )
      .then(({ data }) => {
        setLive(data.isInput);
        setStatus(data.status);
      });
  }, [api, driver, params]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      StateManager.save(driver.state());
    }, 1_000);

    return () => clearInterval(interval);
  }, [driver]);

  if (!params.videoId || params.videoId.length === 0) {
    return null;
  }

  return (
    <div className={styles.player}>
      <div className={styles.overlay}>
        {live !== undefined && (
          <Header live={live} status={status} params={params} />
        )}
        {live === false && <Progress driver={driver} />}
        <Controls driver={driver} />
      </div>
    </div>
  );
}

export default Player;
