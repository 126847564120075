import axios from "axios";

const client = axios.create({
  baseURL: `https://customer-mjfr8ec1ul8r2h08.cloudflarestream.com`,
});

const useApi = () => {
  return {
    get: client.get,
    post: client.post,
    put: client.put,
    patch: client.patch,
    delete: client.delete,
  };
};

export default useApi;
