import dashjs from "dashjs";
import StateManager from "../manager/StateManager";
import { DriverInterface } from "./DriverInterface";
import AbstractDriver from "./AbstractDriver";
import {
  PlayerParams,
  PlayerState,
  VideoBitrate,
  VideoProgress,
} from "../types";

export default class DashDriver
  extends AbstractDriver
  implements DriverInterface
{
  private player: dashjs.MediaPlayerClass;

  constructor() {
    super();
    this.player = dashjs.MediaPlayer().create();
  }

  public initialize(
    element: HTMLVideoElement,
    params: PlayerParams,
    state: PlayerState
  ) {
    console.log("[DRIVER] Initializing DASH driver:", params);

    let qualityInitialized = false;

    this.player.on("streamInitialized", () => {
      if (qualityInitialized) return;
      qualityInitialized = true;
      this.quality(state.bitrate);
    });

    this.player.initialize();
    this.player.attachView(element);
    this.player.setAutoPlay(params.autoPlay);
    this.player.setMute(state.muted);
    this.player.setVolume(state.volume);
    this.player.attachSource(this.buildVideoUrl(params.videoId));
  }

  public buildVideoUrl(videoId: string): string {
    return `https://customer-mjfr8ec1ul8r2h08.cloudflarestream.com/${videoId}/manifest/video.mpd?betaCodecSuggestion=av1`;
  }

  public ready(): boolean {
    return this.player.isReady();
  }

  public pause(): void {
    this.player.pause();
  }

  public play(): void {
    this.player.play();
  }

  public volume(value: number): void {
    this.player.setVolume(value);
    this.player.setMute(value <= 0.01);
  }

  public mute(): void {
    this.player.setMute(true);
  }

  public unmute(): void {
    this.player.setMute(false);
  }

  public seek(time: number): void {
    this.player.seek(time);
  }

  public quality(value: number): void {
    if (value === -1) {
      this.player.updateSettings({
        streaming: {
          abr: {
            autoSwitchBitrate: { video: true, audio: true },
          },
        },
      });
    } else {
      this.player.updateSettings({
        streaming: {
          abr: {
            autoSwitchBitrate: { video: false, audio: false },
          },
        },
      });

      this.player.setQualityFor("video", this.bitratesMap[value]);
    }
  }

  public progress(): VideoProgress {
    if (!this.player.isReady()) {
      return {
        duration: 0,
        elapsed: 0,
      };
    }

    return {
      duration: this.player.duration() || 0,
      elapsed: this.player.time() || 0,
    };
  }

  public bitrates(): VideoBitrate[] {
    if (!this.player.isReady()) {
      return [];
    }

    return this.player.getBitrateInfoListFor("video").map((b) => ({
      index: b.qualityIndex,
      bitrate: b.bitrate,
      width: b.width,
      height: b.height,
    }));
  }

  public state(): PlayerState {
    if (!this.player.isReady()) {
      return StateManager.default();
    }

    const settings = this.player.getSettings();

    return {
      bitrate: settings.streaming?.abr?.autoSwitchBitrate?.video
        ? -1
        : this.qualitiesMap[this.player.getQualityFor("video")],
      playing: !this.player.isPaused(),
      volume: this.player.getVolume(),
      muted: this.player.isMuted(),
    };
  }
}
