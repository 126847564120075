import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Player from "./components/Player";
import DriverFactory from "./driver/DriverFactory";
import StateManager from "./manager/StateManager";
import { PlayerParams } from "./types";
import "./index.css";

const search = new URLSearchParams(window.location.search);
const driver = DriverFactory.make();

const params: PlayerParams = {
  videoId: search.get("w") || "",
  autoPlay: search.get("autoPlay") === "true",
};

const element = document.getElementById("video");
if (element) {
  driver.initialize(element as HTMLVideoElement, params, StateManager.load());
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Player driver={driver} params={params} />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
