import { PropsWithChildren } from "react";
import styles from "./IconButton.module.scss";

type Props = PropsWithChildren<{
  onClick: () => void;
}>;

function IconButton({ children, onClick }: Props) {
  return (
    <button className={styles.button} onClick={onClick}>
      {children}
    </button>
  );
}

export default IconButton;
