import { DriverInterface } from "./DriverInterface";
import StateManager from "../manager/StateManager";
import {
  PlayerParams,
  PlayerState,
  VideoBitrate,
  VideoProgress,
} from "../types";
import AbstractDriver from "./AbstractDriver";

export default class NativeDriver
  extends AbstractDriver
  implements DriverInterface
{
  protected videoElement?: HTMLVideoElement;

  public buildVideoUrl(videoId: string): string {
    return `https://customer-mjfr8ec1ul8r2h08.cloudflarestream.com/${videoId}/manifest/video.m3u8`;
  }

  public initialize(
    element: HTMLVideoElement,
    params: PlayerParams,
    state: PlayerState
  ): void {
    console.log("[DRIVER] Initializing native driver:", params);

    this.videoElement = element;

    this.videoElement.src = this.buildVideoUrl(params.videoId);

    if (params.autoPlay) {
      this.videoElement.autoplay = true;
      this.videoElement.muted = true;
    }
  }

  public pause(): void {
    if (this.videoElement) {
      this.videoElement.pause();
    }
  }

  public play(): void {
    if (this.videoElement) {
      this.videoElement.play();
    }
  }

  public quality(value: number): void {}

  public seek(time: number): void {
    if (this.videoElement) {
      this.videoElement.currentTime = time;
    }
  }

  public mute(): void {
    if (this.videoElement) {
      this.videoElement.muted = true;
    }
  }

  public unmute(): void {
    if (this.videoElement) {
      this.videoElement.muted = false;
    }
  }

  public volume(value: number): void {
    if (this.videoElement) {
      this.videoElement.muted = value <= 0.01;
      this.videoElement.volume = value;
    }
  }

  public ready(): boolean {
    return this.videoElement !== undefined;
  }

  public bitrates(): VideoBitrate[] {
    return [];
  }

  public state(): PlayerState {
    if (this.videoElement) {
      return {
        muted: this.videoElement.muted,
        volume: this.videoElement.volume,
        playing: !this.videoElement.paused,
        bitrate: -1,
      };
    }

    return StateManager.default();
  }

  public progress(): VideoProgress {
    if (this.videoElement) {
      return {
        duration: this.videoElement.duration,
        elapsed: this.videoElement.currentTime,
      };
    }

    return {
      duration: 0,
      elapsed: 0,
    };
  }
}
