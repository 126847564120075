import React from "react";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/solid";
import Select, { OptionsOrGroups, GroupBase } from "react-select";
import { DriverInterface } from "../../driver/DriverInterface";
import { PlayerState, VideoBitrate } from "../../types";
import IconButton from "../IconButton";
import Volume from "../Volume";
import styles from "./Controls.module.scss";

type Props = {
  driver: DriverInterface;
};

type Option = { value: string; label: string };

function Controls({ driver }: Props) {
  const [state, setState] = React.useState<PlayerState>(driver.state());
  const [bitrates, setBitrates] = React.useState<VideoBitrate[]>(
    driver.bitrates()
  );

  const mutate = React.useCallback(() => {
    if (!driver.ready()) return;

    setState(driver.state());
    setBitrates(driver.bitrates());
  }, [driver]);

  const play = () => {
    driver.play();
    mutate();
  };

  const pause = () => {
    driver.pause();
    mutate();
  };

  const mute = () => {
    driver.mute();
    mutate();
  };

  const unmute = () => {
    driver.unmute();
    mutate();
  };

  const setVolume = (value: number) => {
    driver.volume(value);
    mutate();
  };

  React.useEffect(() => {
    const interval = setInterval(mutate, 1_000);
    return () => clearInterval(interval);
  }, [mutate]);

  const options: OptionsOrGroups<
    Option,
    GroupBase<Option>
  > = React.useMemo(() => {
    const options: { value: string; label: string }[] = [];

    for (const bitrate of bitrates) {
      options.push({
        value: bitrate.height.toString(),
        label: `${bitrate.height}p`,
      });
    }

    options.push({ value: "-1", label: "auto" });

    return options;
  }, [bitrates]);

  if (!driver.ready()) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <IconButton onClick={() => (state.playing ? pause() : play())}>
          {state.playing ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <IconButton onClick={() => (state.muted ? unmute() : mute())}>
          {state.muted ? <SpeakerXMarkIcon /> : <SpeakerWaveIcon />}
        </IconButton>
        <Volume
          value={state.muted ? 0 : state.volume}
          onChange={(value) => setVolume(value)}
        />
      </div>
      <div>
        <Select
          value={{
            value: state.bitrate.toString(),
            label: state.bitrate === -1 ? "auto" : `${state.bitrate}p`,
          }}
          options={options}
          onChange={(option) => {
            if (option) {
              driver.quality(Number(option.value));
              mutate();
            }
          }}
          menuPlacement="top"
          classNames={{
            control: () => styles.select_control,
            singleValue: () => styles.select_value,
            indicatorsContainer: () => styles.select_indicators,
            menu: () => styles.select_menu,
            option: () => styles.select_option_value,
          }}
          unstyled
        />
        <IconButton
          onClick={() =>
            Boolean(document.fullscreenElement)
              ? document.exitFullscreen()
              : document.body.requestFullscreen()
          }
        >
          {Boolean(document.fullscreenElement) ? (
            <ArrowsPointingInIcon />
          ) : (
            <ArrowsPointingOutIcon />
          )}
        </IconButton>
      </div>
    </div>
  );
}

export default Controls;
