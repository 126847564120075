import React from "react";
import { UserIcon } from "@heroicons/react/24/solid";
import styles from "./Header.module.scss";
import { PlayerParams, StreamStatus } from "../../types";
import useApi from "../../hooks/useApi";

type Props = {
  live: boolean;
  status?: StreamStatus;
  params: PlayerParams;
};

function Header({ live, status, params }: Props) {
  const api = useApi();

  const [viewers, setViewers] = React.useState<number>();

  React.useEffect(() => {
    if (!live || status !== "ready") return;

    const interval = setInterval(() => {
      api
        .get<{ liveViewers: number }>(`/${params.videoId}/views`)
        .then(({ data }) => setViewers(data.liveViewers));
    }, 5_000);

    return () => clearInterval(interval);
  }, [api, live, status, params.videoId]);

  return (
    <div className={styles.header}>
      <div></div>
      <div>
        {viewers !== undefined && (
          <p className={styles.audience}>
            <UserIcon />
            {viewers}
          </p>
        )}
        {live &&
          (status === "ready" ? (
            <p className={styles.live}>Ao vivo</p>
          ) : (
            <p className={styles.offline}>Offline</p>
          ))}
      </div>
    </div>
  );
}

export default Header;
