export default class AbstractDriver {
  protected readonly qualitiesMap: Record<number, number> = {
    [-1]: -1,
    0: 240,
    1: 360,
    2: 480,
    3: 720,
    4: 1080,
    5: 1440,
  };

  protected readonly bitratesMap: Record<number, number> = {
    240: 0,
    360: 1,
    480: 2,
    720: 3,
    1080: 4,
    1440: 5,
  };
}
