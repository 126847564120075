import Hls from "hls.js";
import StateManager from "../manager/StateManager";
import { PlayerParams, PlayerState, VideoBitrate } from "../types";
import NativeDriver from "./NativeDriver";

export default class HLSDriver extends NativeDriver {
  private hls?: Hls;

  public buildVideoUrl(videoId: string): string {
    return `https://customer-mjfr8ec1ul8r2h08.cloudflarestream.com/${videoId}/manifest/video.m3u8?betaCodecSuggestion=av1`;
  }

  public initialize(
    element: HTMLVideoElement,
    params: PlayerParams,
    state: PlayerState
  ): void {
    console.log("[DRIVER] Initializing HLS driver:", params);

    this.videoElement = element;

    if (params.autoPlay) {
      element.autoplay = true;
      element.muted = true;
    }

    this.hls = new Hls();
    this.hls.loadSource(this.buildVideoUrl(params.videoId));
    this.hls.attachMedia(element);
    this.hls.lowLatencyMode = true;
  }

  public quality(value: number): void {
    if (!this.hls) return;

    if (value === -1) {
      this.hls.nextLevel = -1;
    } else {
      this.hls.nextLevel = this.bitratesMap[value];
    }
  }

  public ready(): boolean {
    return this.hls !== undefined;
  }

  public bitrates(): VideoBitrate[] {
    return (
      this.hls?.levels?.map((level) => ({
        index: level.id,
        bitrate: level.bitrate,
        width: level.width,
        height: level.height,
      })) || []
    );
  }

  public state(): PlayerState {
    if (this.videoElement && this.hls) {
      return {
        muted: this.videoElement.muted,
        volume: this.videoElement.volume,
        playing: !this.videoElement.paused,
        bitrate: this.hls.autoLevelEnabled
          ? -1
          : this.qualitiesMap[this.hls.currentLevel],
      };
    }

    return StateManager.default();
  }
}
